
  :root {
    /* global */
    --main-color: #ed7635;
    --main-color-light: #f8c7ae;
    --main-green: #007a7b;
    --main-andalus-border: #e9e6e5;
    --main-search-bar: #fcecd9;
    --main-gray: #6e6b68;
    --main-black: #32302d;
    --main-white: #ffffff;
    --main-background: #f7f5f4;
    --font-family: Montserrat, sans-serif;

    --main-left-menu-bg-bar: var(--main-white);
    --main-left-menu-extend-bg-bar: var(--main-background);
    --main-left-menu-font-icon: var(--main-black);
    --main-left-menu-highlight-color: var(--main-color);
    --main-left-menu-highlight-stripe-color: var(--main-color);
    --main-top-menu-bar: var(--main-white);
    --main-button-color: var(--main-color);
    --main-background-color: var(--main-background);
    --main-font-family: var(--font-family);
    --main-font-color: var(--main-black);
    --main-button-text-color: var(--main-black);
    --main-extend-narrow-bg: var(--main-white);
    --main-profile-drawer-bg: var(--main-white);
    --main-layout-background-color: var(--main-white);

    --preview-theme-left-menu-bg-bar: var(--main-white);
    --preview-theme-left-menu-extend-bg-bar: var(--main-background);
    --preview-theme-left-menu-font-icon: var(--main-black);
    --preview-theme-left-menu-highlight-color: var(--main-color);
    --preview-theme-left-menu-highlight-stripe-color: var(--main-color);
    --preview-theme-search-bar: var(--main-white);
    --preview-theme-top-menu-bar: var(--main-white);
    --preview-theme-button-color: var(--main-color);
    --preview-theme-background-color: var(--main-background);
    --preview-theme-font-family: var(--font-family);
    --preview-theme-font-color: var(--main-black);
    --preview-theme-button-text-color: var(--main-black);
    --preview-theme-extend-narrow-bg: var(--main-white);
    --preview-theme-profile-drawer-bg: var(--main-white);
    --preview-theme-layout-background-color: var(--main-white);
  }
  body {
    min-height: 100%;
    padding: 0px;
    color: var(--main-font-color) !important;
}

  ::-webkit-scrollbar {
    height: 0.5rem;
    width: 0.5rem;
}

  ::-webkit-scrollbar-thumb {
    height: 0.5rem;
    border-radius: 0.5rem;
    --tw-bg-opacity: 1 !important;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity)) !important;
    --tw-shadow: inset 0 0 8px rgba(0,0,0,0.3);
    --tw-shadow-colored: inset 0 0 8px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

  ::-webkit-scrollbar-track {
    border-radius: 0.5rem;
    background-color: transparent;
    --tw-shadow: inset 0 0 8px rgba(0,0,0,0.3);
    --tw-shadow-colored: inset 0 0 8px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

  ::selection {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(59 130 246 / var(--tw-bg-opacity)) !important;
}
*, ::before, ::after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
}
::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
}
.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1rem;
    padding-left: 1rem;
}
@media (min-width: 1279px) {
    .container {
        max-width: 1279px;
    }
}
@media (min-width: 1535px) {
    .container {
        max-width: 1535px;
    }
}
@media (min-width: 1024px) {
    .style_input_custom_login_page {
      font-size: 0.875rem !important;
      font-weight: 1.25rem !important;
    }
  }
.label_custom_login_page {
    margin-left: 0.5rem;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 400;
    font-style: normal;
    color: var(--main-font-color);
}
@media (max-width: 1279px) {
    .label_custom_login_page {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
}
.style_input_custom_login_page {
    height: 3rem;
    width: 100%;
    border-radius: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 0.75rem;
    line-height: 1rem;
    --tw-text-opacity: 1;
    color: rgb(50 48 45 / var(--tw-text-opacity));
}
@media (max-width: 1279px) {
    .style_input_custom_login_page {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
}
.styte_button_outline {
    width: 100%;
    border-radius: 1rem;
    border-color: var(--main-button-color) !important;
    font-family: var(--main-font-family);
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
    color: var(--main-button-color);
}
.styte_button_outline:hover {
    border-color: var(--main-button-color) !important;
    color: var(--main-button-color) !important;
}
@media (max-width: 1279px) {
    .styte_button_outline {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}
.styte_button_outline_light {
    width: 100%;
    border-radius: 1rem;
    font-family: var(--main-font-family);
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
}
.styte_button_outline_light:hover {
    border-color: var(--main-button-color) !important;
    color: var(--main-button-color) !important;
}
@media (max-width: 1279px) {
    .styte_button_outline_light {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}
.styte_button_primary {
    width: 100%;
    border-radius: 1rem;
    border-width: 0px;
    background-color: var(--main-button-color) !important;
    font-family: var(--main-font-family);
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
.styte_button_primary:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
@media (max-width: 1279px) {
    .styte_button_primary {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}
.styte_button_primary_login_page {
    width: 100%;
    border-radius: 1rem;
    border-width: 0px;
    background-color: var(--main-button-color) !important;
    font-family: var(--main-font-family);
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
.styte_button_primary_login_page:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
@media (max-width: 1279px) {
    .styte_button_primary_login_page {
        font-size: 1.125rem;
        line-height: 1.75rem;
    }
}
.content-title {
    margin-bottom: 1.5rem;
    font-family: var(--main-font-family);
    font-size: 28px;
    font-weight: 700;
    letter-spacing: -0.025em;
    color: var(--main-font-color);
}
.filter-card {
    display: flex;
    height: auto;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 1.5rem;
    background-color: var(--main-search-bar);
    padding: 2rem;
}
.filter-title {
    margin-bottom: 1rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 700;
    letter-spacing: -0.025em;
}
@media (max-width: 1279px) {
    .filter-title {
        margin-bottom: 1.5rem;
        font-size: 1.5rem;
        line-height: 2rem;
    }
}
.theme-title-section {
    margin-bottom: 0.5rem;
    width: 100%;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
}
.icon-button path {
    fill: var(--main-button-color);
}
.\!visible {
    visibility: visible !important;
}
.visible {
    visibility: visible !important;
}
.fixed {
    position: fixed !important;
}
.absolute {
    position: absolute !important;
}
.relative {
    position: relative !important;
}
.bottom-0 {
    bottom: 0px !important;
}
.bottom-1 {
    bottom: 0.25rem !important;
}
.bottom-8 {
    bottom: 2rem !important;
}
.bottom-\[110px\] {
    bottom: 110px !important;
}
.left-0 {
    left: 0px !important;
}
.left-\[-15px\] {
    left: -15px !important;
}
.left-\[216px\] {
    left: 216px !important;
}
.left-\[60\%\] {
    left: 60% !important;
}
.right-0 {
    right: 0px !important;
}
.right-2 {
    right: 0.5rem !important;
}
.right-4 {
    right: 1rem !important;
}
.right-\[-16px\] {
    right: -16px !important;
}
.right-\[2\%\] {
    right: 2% !important;
}
.top-0 {
    top: 0px !important;
}
.top-1 {
    top: 0.25rem !important;
}
.top-3 {
    top: 0.75rem !important;
}
.top-4 {
    top: 1rem !important;
}
.top-\[-45px\] {
    top: -45px !important;
}
.top-\[-65px\] {
    top: -65px !important;
}
.top-\[-70px\] {
    top: -70px !important;
}
.top-\[50\%\] {
    top: 50% !important;
}
.-z-10 {
    z-index: -10 !important;
}
.z-10 {
    z-index: 10 !important;
}
.z-20 {
    z-index: 20 !important;
}
.z-30 {
    z-index: 30 !important;
}
.z-50 {
    z-index: 50 !important;
}
.float-right {
    float: right !important;
}
.m-0 {
    margin: 0px !important;
}
.m-2 {
    margin: 0.5rem !important;
}
.m-\[5px\] {
    margin: 5px !important;
}
.m-auto {
    margin: auto !important;
}
.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}
.my-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
.my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
}
.my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}
.my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}
.mb-0 {
    margin-bottom: 0px !important;
}
.mb-1 {
    margin-bottom: 0.25rem !important;
}
.mb-10 {
    margin-bottom: 2.5rem !important;
}
.mb-2 {
    margin-bottom: 0.5rem !important;
}
.mb-3 {
    margin-bottom: 0.75rem !important;
}
.mb-4 {
    margin-bottom: 1rem !important;
}
.mb-5 {
    margin-bottom: 1.25rem !important;
}
.mb-6 {
    margin-bottom: 1.5rem !important;
}
.mb-\[-20px\] {
    margin-bottom: -20px !important;
}
.mb-\[26px\] {
    margin-bottom: 26px !important;
}
.ml-1 {
    margin-left: 0.25rem !important;
}
.ml-2 {
    margin-left: 0.5rem !important;
}
.ml-3 {
    margin-left: 0.75rem !important;
}
.ml-4 {
    margin-left: 1rem !important;
}
.ml-5 {
    margin-left: 1.25rem !important;
}
.ml-\[200px\] {
    margin-left: 200px !important;
}
.ml-\[78px\] {
    margin-left: 78px !important;
}
.mr-0 {
    margin-right: 0px !important;
}
.mr-1 {
    margin-right: 0.25rem !important;
}
.mr-2 {
    margin-right: 0.5rem !important;
}
.mr-3 {
    margin-right: 0.75rem !important;
}
.mr-5 {
    margin-right: 1.25rem !important;
}
.mr-6 {
    margin-right: 1.5rem !important;
}
.mr-\[-10px\] {
    margin-right: -10px !important;
}
.mt-1 {
    margin-top: 0.25rem !important;
}
.mt-10 {
    margin-top: 2.5rem !important;
}
.mt-16 {
    margin-top: 4rem !important;
}
.mt-2 {
    margin-top: 0.5rem !important;
}
.mt-24 {
    margin-top: 6rem !important;
}
.mt-3 {
    margin-top: 0.75rem !important;
}
.mt-4 {
    margin-top: 1rem !important;
}
.mt-5 {
    margin-top: 1.25rem !important;
}
.mt-6 {
    margin-top: 1.5rem !important;
}
.mt-8 {
    margin-top: 2rem !important;
}
.mt-\[26px\] {
    margin-top: 26px !important;
}
.mt-\[30px\] {
    margin-top: 30px !important;
}
.mt-\[4px\] {
    margin-top: 4px !important;
}
.mt-\[600px\] {
    margin-top: 600px !important;
}
.block {
    display: block !important;
}
.inline-block {
    display: inline-block !important;
}
.inline {
    display: inline !important;
}
.flex {
    display: flex !important;
}
.table {
    display: table !important;
}
.grid {
    display: grid !important;
}
.hidden {
    display: none !important;
}
.h-0 {
    height: 0px !important;
}
.h-10 {
    height: 2.5rem !important;
}
.h-11 {
    height: 2.75rem !important;
}
.h-12 {
    height: 3rem !important;
}
.h-14 {
    height: 3.5rem !important;
}
.h-\[1378px\] {
    height: 1378px !important;
}
.h-\[162px\] {
    height: 162px !important;
}
.h-\[170px\] {
    height: 170px !important;
}
.h-\[20px\] {
    height: 20px !important;
}
.h-\[240px\] {
    height: 240px !important;
}
.h-\[24px\] {
    height: 24px !important;
}
.h-\[272px\] {
    height: 272px !important;
}
.h-\[320px\] {
    height: 320px !important;
}
.h-\[33\.33px\] {
    height: 33.33px !important;
}
.h-\[34px\] {
    height: 34px !important;
}
.h-\[36px\] {
    height: 36px !important;
}
.h-\[400px\] {
    height: 400px !important;
}
.h-\[40px\] {
    height: 40px !important;
}
.h-\[44px\] {
    height: 44px !important;
}
.h-\[47px\] {
    height: 47px !important;
}
.h-\[48px\] {
    height: 48px !important;
}
.h-\[57px\] {
    height: 57px !important;
}
.h-\[60vh\] {
    height: 60vh !important;
}
.h-\[72px\] {
    height: 72px !important;
}
.h-\[897px\] {
    height: 897px !important;
}
.h-\[calc\(100vh-65px\)\] {
    height: calc(100vh - 65px) !important;
}
.h-auto {
    height: auto !important;
}
.h-fit {
    height: -moz-fit-content !important;
    height: fit-content !important;
}
.h-full {
    height: 100% !important;
}
.min-h-\[100px\] {
    min-height: 100px !important;
}
.min-h-\[240px\] {
    min-height: 240px !important;
}
.min-h-\[3rem\] {
    min-height: 3rem !important;
}
.min-h-\[42vh\] {
    min-height: 42vh !important;
}
.min-h-\[calc\(100vh-64px\)\] {
    min-height: calc(100vh - 64px) !important;
}
.w-12 {
    width: 3rem !important;
}
.w-2\/3 {
    width: 66.666667% !important;
}
.w-20 {
    width: 5rem !important;
}
.w-32 {
    width: 8rem !important;
}
.w-48 {
    width: 12rem !important;
}
.w-\[10\%\] {
    width: 10% !important;
}
.w-\[100\%\] {
    width: 100% !important;
}
.w-\[100px\] {
    width: 100px !important;
}
.w-\[101\.5\%\] {
    width: 101.5% !important;
}
.w-\[110px\] {
    width: 110px !important;
}
.w-\[116px\] {
    width: 116px !important;
}
.w-\[118px\] {
    width: 118px !important;
}
.w-\[120px\] {
    width: 120px !important;
}
.w-\[138px\] {
    width: 138px !important;
}
.w-\[1440px\] {
    width: 1440px !important;
}
.w-\[192px\] {
    width: 192px !important;
}
.w-\[20\%\] {
    width: 20% !important;
}
.w-\[200px\] {
    width: 200px !important;
}
.w-\[208px\] {
    width: 208px !important;
}
.w-\[20px\] {
    width: 20px !important;
}
.w-\[232px\] {
    width: 232px !important;
}
.w-\[24px\] {
    width: 24px !important;
}
.w-\[25\%\] {
    width: 25% !important;
}
.w-\[26\%\] {
    width: 26% !important;
}
.w-\[26\.67px\] {
    width: 26.67px !important;
}
.w-\[30\%\] {
    width: 30% !important;
}
.w-\[320px\] {
    width: 320px !important;
}
.w-\[35\%\] {
    width: 35% !important;
}
.w-\[38\%\] {
    width: 38% !important;
}
.w-\[40\%\] {
    width: 40% !important;
}
.w-\[40px\] {
    width: 40px !important;
}
.w-\[450px\] {
    width: 450px !important;
}
.w-\[476px\] {
    width: 476px !important;
}
.w-\[484px\] {
    width: 484px !important;
}
.w-\[49\%\] {
    width: 49% !important;
}
.w-\[50\%\] {
    width: 50% !important;
}
.w-\[512px\] {
    width: 512px !important;
}
.w-\[60\%\] {
    width: 60% !important;
}
.w-\[68\%\] {
    width: 68% !important;
}
.w-\[70\%\] {
    width: 70% !important;
}
.w-\[75\%\] {
    width: 75% !important;
}
.w-\[80\%\] {
    width: 80% !important;
}
.w-\[840px\] {
    width: 840px !important;
}
.w-\[88px\] {
    width: 88px !important;
}
.w-\[89\.4\%\] {
    width: 89.4% !important;
}
.w-\[9\%\] {
    width: 9% !important;
}
.w-\[90\%\] {
    width: 90% !important;
}
.w-\[96\.4\%\] {
    width: 96.4% !important;
}
.w-\[calc\(10\%_-_0\.7rem\)\] {
    width: calc(10% - 0.7rem) !important;
}
.w-\[calc\(15\%_-_0\.75rem\)\] {
    width: calc(15% - 0.75rem) !important;
}
.w-\[calc\(15\%_-_0\.7rem\)\] {
    width: calc(15% - 0.7rem) !important;
}
.w-\[calc\(20\%_-_0\.67rem\)\] {
    width: calc(20% - 0.67rem) !important;
}
.w-\[calc\(28\.3\%_-_0\.8rem\)\] {
    width: calc(28.3% - 0.8rem) !important;
}
.w-\[calc\(42\.5\%_-_0\.7rem\)\] {
    width: calc(42.5% - 0.7rem) !important;
}
.w-\[calc\(50\%_-_0\.375rem\)\] {
    width: calc(50% - 0.375rem) !important;
}
.w-\[calc\(50\%_-_0\.5rem\)\] {
    width: calc(50% - 0.5rem) !important;
}
.w-\[calc\(60\%_-_0\.67rem\)\] {
    width: calc(60% - 0.67rem) !important;
}
.w-\[calc\(70\%_-_0\.75rem\)\] {
    width: calc(70% - 0.75rem) !important;
}
.w-\[calc\(75\%_-_0\.7rem\)\] {
    width: calc(75% - 0.7rem) !important;
}
.w-fit {
    width: -moz-fit-content !important;
    width: fit-content !important;
}
.w-full {
    width: 100% !important;
}
.min-w-\[110px\] {
    min-width: 110px !important;
}
.min-w-\[120px\] {
    min-width: 120px !important;
}
.min-w-\[144px\] {
    min-width: 144px !important;
}
.min-w-\[167px\] {
    min-width: 167px !important;
}
.min-w-\[188px\] {
    min-width: 188px !important;
}
.min-w-\[270px\] {
    min-width: 270px !important;
}
.min-w-\[72px\] {
    min-width: 72px !important;
}
.min-w-fit {
    min-width: -moz-fit-content !important;
    min-width: fit-content !important;
}
.max-w-\[20\%\] {
    max-width: 20% !important;
}
.max-w-\[476px\] {
    max-width: 476px !important;
}
.max-w-\[49\%\] {
    max-width: 49% !important;
}
.max-w-\[65\%\] {
    max-width: 65% !important;
}
.max-w-full {
    max-width: 100% !important;
}
.flex-1 {
    flex: 1 1 0% !important;
}
.flex-\[62\%\] {
    flex: 62% !important;
}
.flex-auto {
    flex: 1 1 auto !important;
}
.flex-none {
    flex: none !important;
}
.grow {
    flex-grow: 1 !important;
}
.basis-1\/2 {
    flex-basis: 50% !important;
}
.basis-1\/3 {
    flex-basis: 33.333333% !important;
}
.basis-2\/5 {
    flex-basis: 40% !important;
}
.basis-3\/5 {
    flex-basis: 60% !important;
}
.basis-\[100\%\] {
    flex-basis: 100% !important;
}
.basis-\[15\%\] {
    flex-basis: 15% !important;
}
.basis-\[18\.5\%\] {
    flex-basis: 18.5% !important;
}
.basis-\[38\%\] {
    flex-basis: 38% !important;
}
.basis-\[38\.6\%\] {
    flex-basis: 38.6% !important;
}
.basis-\[calc\(15\%_-_0\.75rem\)\] {
    flex-basis: calc(15% - 0.75rem) !important;
}
.basis-\[calc\(28\.3\%_-_0\.75rem\)\] {
    flex-basis: calc(28.3% - 0.75rem) !important;
}
.basis-\[calc\(42\.5\%_-_0\.67rem\)\] {
    flex-basis: calc(42.5% - 0.67rem) !important;
}
.basis-full {
    flex-basis: 100% !important;
}
.translate-y-\[-50\%\] {
    --tw-translate-y: -50% !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.cursor-not-allowed {
    cursor: not-allowed !important;
}
.cursor-pointer {
    cursor: pointer !important;
}
.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}
.flex-row {
    flex-direction: row !important;
}
.flex-col {
    flex-direction: column !important;
}
.flex-wrap {
    flex-wrap: wrap !important;
}
.items-start {
    align-items: flex-start !important;
}
.items-end {
    align-items: flex-end !important;
}
.items-center {
    align-items: center !important;
}
.justify-start {
    justify-content: flex-start !important;
}
.justify-end {
    justify-content: flex-end !important;
}
.justify-center {
    justify-content: center !important;
}
.justify-between {
    justify-content: space-between !important;
}
.gap-1 {
    gap: 0.25rem !important;
}
.gap-10 {
    gap: 2.5rem !important;
}
.gap-2 {
    gap: 0.5rem !important;
}
.gap-3 {
    gap: 0.75rem !important;
}
.gap-4 {
    gap: 1rem !important;
}
.gap-5 {
    gap: 1.25rem !important;
}
.gap-6 {
    gap: 1.5rem !important;
}
.gap-8 {
    gap: 2rem !important;
}
.gap-\[10px\] {
    gap: 10px !important;
}
.gap-\[24px\] {
    gap: 24px !important;
}
.gap-\[8px\] {
    gap: 8px !important;
}
.gap-x-2 {
    column-gap: 0.5rem !important;
}
.gap-x-3 {
    column-gap: 0.75rem !important;
}
.gap-x-4 {
    column-gap: 1rem !important;
}
.gap-x-6 {
    column-gap: 1.5rem !important;
}
.gap-x-8 {
    column-gap: 2rem !important;
}
.gap-x-\[10px\] {
    column-gap: 10px !important;
}
.gap-y-3 {
    row-gap: 0.75rem !important;
}
.gap-y-5 {
    row-gap: 1.25rem !important;
}
.gap-y-6 {
    row-gap: 1.5rem !important;
}
.self-start {
    align-self: flex-start !important;
}
.self-center {
    align-self: center !important;
}
.self-baseline {
    align-self: baseline !important;
}
.overflow-auto {
    overflow: auto !important;
}
.overflow-hidden {
    overflow: hidden !important;
}
.overflow-y-auto {
    overflow-y: auto !important;
}
.overflow-x-hidden {
    overflow-x: hidden !important;
}
.text-ellipsis {
    text-overflow: ellipsis !important;
}
.whitespace-nowrap {
    white-space: nowrap !important;
}
.break-words {
    overflow-wrap: break-word !important;
}
.rounded {
    border-radius: 0.25rem !important;
}
.rounded-2xl {
    border-radius: 1rem !important;
}
.rounded-3xl {
    border-radius: 1.5rem !important;
}
.rounded-\[100px\] {
    border-radius: 100px !important;
}
.rounded-full {
    border-radius: 9999px !important;
}
.rounded-lg {
    border-radius: 0.5rem !important;
}
.rounded-xl {
    border-radius: 0.75rem !important;
}
.rounded-r-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
}
.rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem !important;
}
.rounded-tl-3xl {
    border-top-left-radius: 1.5rem !important;
}
.border {
    border-width: 1px !important;
}
.border-0 {
    border-width: 0px !important;
}
.border-\[1px\] {
    border-width: 1px !important;
}
.border-r-0 {
    border-right-width: 0px !important;
}
.border-solid {
    border-style: solid !important;
}
.border-none {
    border-style: none !important;
}
.\!border-\[\#E9E6E5\] {
    --tw-border-opacity: 1 !important;
    border-color: rgb(233 230 229 / var(--tw-border-opacity)) !important;
}
.\!border-main-button-color {
    border-color: var(--main-button-color) !important;
}
.\!border-preview-theme-button-color {
    border-color: var(--preview-theme-button-color) !important;
}
.border-\[\#D1CDCB\] {
    --tw-border-opacity: 1 !important;
    border-color: rgb(209 205 203 / var(--tw-border-opacity)) !important;
}
.border-\[\#E9E6E5\] {
    --tw-border-opacity: 1 !important;
    border-color: rgb(233 230 229 / var(--tw-border-opacity)) !important;
}
.border-\[\#d9d9d9\] {
    --tw-border-opacity: 1 !important;
    border-color: rgb(217 217 217 / var(--tw-border-opacity)) !important;
}
.border-\[color\:var\(--main-button-color\)\] {
    border-color: var(--main-button-color) !important;
}
.border-gray-300 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
}
.border-gray-700 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(55 65 81 / var(--tw-border-opacity)) !important;
}
.border-main-button-color {
    border-color: var(--main-button-color) !important;
}
.border-preview-theme-button-color {
    border-color: var(--preview-theme-button-color) !important;
}
.border-red-600 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(220 38 38 / var(--tw-border-opacity)) !important;
}
.border-red-800 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(153 27 27 / var(--tw-border-opacity)) !important;
}
.border-transparent {
    border-color: transparent !important;
}
.\!bg-andalus-border {
    background-color: var(--andalus-border) !important;
}
.\!bg-main-button-color {
    background-color: var(--main-button-color) !important;
}
.\!bg-preview-theme-button-color {
    background-color: var(--preview-theme-button-color) !important;
}
.\!bg-preview-theme-left-menu-bg {
    background-color: var(--preview-theme-left-menu-bg-bar) !important;
}
.bg-\[\#E6F2F2\] {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(230 242 242 / var(--tw-bg-opacity)) !important;
}
.bg-\[\#E9E6E5\] {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(233 230 229 / var(--tw-bg-opacity)) !important;
}
.bg-\[\#ED7635\] {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(237 118 53 / var(--tw-bg-opacity)) !important;
}
.bg-\[\#F1915D\] {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(241 145 93 / var(--tw-bg-opacity)) !important;
}
.bg-\[\#F4F4F4\] {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(244 244 244 / var(--tw-bg-opacity)) !important;
}
.bg-\[\#F7F5F4\] {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(247 245 244 / var(--tw-bg-opacity)) !important;
}
.bg-\[\#F8C8AE\] {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(248 200 174 / var(--tw-bg-opacity)) !important;
}
.bg-\[\#FAF9F9\] {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(250 249 249 / var(--tw-bg-opacity)) !important;
}
.bg-\[\#FBE4D7\] {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(251 228 215 / var(--tw-bg-opacity)) !important;
}
.bg-\[\#FCECD9\] {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(252 236 217 / var(--tw-bg-opacity)) !important;
}
.bg-\[\#FFD3D3\] {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 211 211 / var(--tw-bg-opacity)) !important;
}
.bg-\[\#FFFFFF\] {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}
.bg-\[color\:var\(--main-top-menu-bar\)\] {
    background-color: var(--main-top-menu-bar) !important;
}
.bg-main-background-color {
    background-color: var(--main-background-color) !important;
}
.bg-main-button-color {
    background-color: var(--main-button-color) !important;
}
.bg-main-layout-background-color {
    background-color: var(--main-layout-background-color) !important;
}
.bg-main-profile-drawer-bg {
    background-color: var(--main-profile-drawer-bg) !important;
}
.bg-preview-theme-background-color {
    background-color: var(--preview-theme-background-color) !important;
}
.bg-preview-theme-button-color {
    background-color: var(--preview-theme-button-color) !important;
}
.bg-preview-theme-layout-background-color {
    background-color: var(--preview-theme-layout-background-color) !important;
}
.bg-preview-theme-profile-drawer-bg {
    background-color: var(--preview-theme-profile-drawer-bg) !important;
}
.bg-preview-theme-search-bar {
    background-color: var(--preview-theme-search-bar) !important;
}
.bg-preview-theme-top-menu-bar {
    background-color: var(--preview-theme-top-menu-bar) !important;
}
.bg-transparent {
    background-color: transparent !important;
}
.bg-white {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}
.object-contain {
    object-fit: contain !important;
}
.object-cover {
    object-fit: cover !important;
}
.p-0 {
    padding: 0px !important;
}
.p-10 {
    padding: 2.5rem !important;
}
.p-4 {
    padding: 1rem !important;
}
.p-8 {
    padding: 2rem !important;
}
.px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
}
.px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
}
.px-11 {
    padding-left: 2.75rem !important;
    padding-right: 2.75rem !important;
}
.px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
}
.px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
}
.px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}
.px-2\.5 {
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
}
.px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}
.px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}
.px-\[18px\] {
    padding-left: 18px !important;
    padding-right: 18px !important;
}
.px-\[24px\] {
    padding-left: 24px !important;
    padding-right: 24px !important;
}
.px-\[5px\] {
    padding-left: 5px !important;
    padding-right: 5px !important;
}
.px-\[72px\] {
    padding-left: 72px !important;
    padding-right: 72px !important;
}
.px-\[7px\] {
    padding-left: 7px !important;
    padding-right: 7px !important;
}
.py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}
.py-1\.5 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
}
.py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}
.py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
}
.py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}
.py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
}
.py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}
.py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}
.py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
}
.py-\[16px\] {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
}
.py-\[4px\] {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}
.py-\[5px\] {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}
.py-\[64px\] {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
}
.py-\[8px\] {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}
.py-\[8x\] {
    padding-top: 8x !important;
    padding-bottom: 8x !important;
}
.\!pl-0 {
    padding-left: 0px !important;
}
.pb-0 {
    padding-bottom: 0px !important;
}
.pb-20 {
    padding-bottom: 5rem !important;
}
.pb-5 {
    padding-bottom: 1.25rem !important;
}
.pb-\[16px\] {
    padding-bottom: 16px !important;
}
.pb-\[30px\] {
    padding-bottom: 30px !important;
}
.pl-4 {
    padding-left: 1rem !important;
}
.pl-6 {
    padding-left: 1.5rem !important;
}
.pl-8 {
    padding-left: 2rem !important;
}
.pl-\[25px\] {
    padding-left: 25px !important;
}
.pr-10 {
    padding-right: 2.5rem !important;
}
.pr-2 {
    padding-right: 0.5rem !important;
}
.pr-3 {
    padding-right: 0.75rem !important;
}
.pr-4 {
    padding-right: 1rem !important;
}
.pr-5 {
    padding-right: 1.25rem !important;
}
.pr-8 {
    padding-right: 2rem !important;
}
.pt-1 {
    padding-top: 0.25rem !important;
}
.pt-4 {
    padding-top: 1rem !important;
}
.pt-9 {
    padding-top: 2.25rem !important;
}
.\!text-left {
    text-align: left !important;
}
.text-left {
    text-align: left !important;
}
.text-center {
    text-align: center !important;
}
.text-right {
    text-align: right !important;
}
.\!font-fontFamily {
    font-family: var(--main-font-family) !important;
}
.\!font-previewFontFamily {
    font-family: var(--preview-theme-font-family) !important;
}
.font-fontFamily {
    font-family: var(--main-font-family) !important;
}
.font-meriweather {
    font-family: Merriweather, Arial, sans-serif !important;
}
.font-previewFontFamily {
    font-family: var(--preview-theme-font-family) !important;
}
.text-2xl {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
}
.text-3xl {
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
}
.text-\[1\.5rem\] {
    font-size: 1.5rem !important;
}
.text-\[1\.75rem\] {
    font-size: 1.75rem !important;
}
.text-\[10px\] {
    font-size: 10px !important;
}
.text-\[16px\] {
    font-size: 16px !important;
}
.text-\[18px\] {
    font-size: 18px !important;
}
.text-\[1rem\] {
    font-size: 1rem !important;
}
.text-\[24px\] {
    font-size: 24px !important;
}
.text-\[28px\] {
    font-size: 28px !important;
}
.text-base {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
}
.text-lg {
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
}
.text-sm {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
}
.text-xl {
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
}
.text-xs {
    font-size: 0.75rem !important;
    line-height: 1rem !important;
}
.text-xxs {
    font-size: 0.75rem !important;
}
.font-bold {
    font-weight: 700 !important;
}
.font-normal {
    font-weight: 400 !important;
}
.font-semibold {
    font-weight: 600 !important;
}
.uppercase {
    text-transform: uppercase !important;
}
.lowercase {
    text-transform: lowercase !important;
}
.italic {
    font-style: italic !important;
}
.not-italic {
    font-style: normal !important;
}
.leading-5 {
    line-height: 1.25rem !important;
}
.leading-7 {
    line-height: 1.75rem !important;
}
.leading-8 {
    line-height: 2rem !important;
}
.leading-9 {
    line-height: 2.25rem !important;
}
.tracking-\[0\.02em\] {
    letter-spacing: 0.02em !important;
}
.tracking-tight {
    letter-spacing: -0.025em !important;
}
.tracking-tighter {
    letter-spacing: -0.05em !important;
}
.tracking-wider {
    letter-spacing: 0.05em !important;
}
.\!text-\[\#0000003f\] {
    color: #0000003f !important;
}
.\!text-\[\#E9E6E5\] {
    --tw-text-opacity: 1 !important;
    color: rgb(233 230 229 / var(--tw-text-opacity)) !important;
}
.\!text-\[\#bababa\] {
    --tw-text-opacity: 1 !important;
    color: rgb(186 186 186 / var(--tw-text-opacity)) !important;
}
.\!text-black {
    --tw-text-opacity: 1 !important;
    color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}
.\!text-main-button-color {
    color: var(--main-button-color) !important;
}
.\!text-main-font-color {
    color: var(--main-font-color) !important;
}
.\!text-preview-theme-button-color {
    color: var(--preview-theme-button-color) !important;
}
.\!text-preview-theme-font-color {
    color: var(--preview-theme-font-color) !important;
}
.\!text-red-500 {
    --tw-text-opacity: 1 !important;
    color: rgb(239 68 68 / var(--tw-text-opacity)) !important;
}
.\!text-white {
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
.text-\[\#00000040\] {
    color: #00000040 !important;
}
.text-\[\#000000\] {
    --tw-text-opacity: 1 !important;
    color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}
.text-\[\#006262\] {
    --tw-text-opacity: 1 !important;
    color: rgb(0 98 98 / var(--tw-text-opacity)) !important;
}
.text-\[\#007A7B\] {
    --tw-text-opacity: 1 !important;
    color: rgb(0 122 123 / var(--tw-text-opacity)) !important;
}
.text-\[\#32302D\] {
    --tw-text-opacity: 1 !important;
    color: rgb(50 48 45 / var(--tw-text-opacity)) !important;
}
.text-\[\#6E6B68\] {
    --tw-text-opacity: 1 !important;
    color: rgb(110 107 104 / var(--tw-text-opacity)) !important;
}
.text-\[\#AEA8A5\] {
    --tw-text-opacity: 1 !important;
    color: rgb(174 168 165 / var(--tw-text-opacity)) !important;
}
.text-\[\#BE5E2A\] {
    --tw-text-opacity: 1 !important;
    color: rgb(190 94 42 / var(--tw-text-opacity)) !important;
}
.text-\[\#E9E6E5\] {
    --tw-text-opacity: 1 !important;
    color: rgb(233 230 229 / var(--tw-text-opacity)) !important;
}
.text-\[\#EB5757\] {
    --tw-text-opacity: 1 !important;
    color: rgb(235 87 87 / var(--tw-text-opacity)) !important;
}
.text-\[\#ED7635\] {
    --tw-text-opacity: 1 !important;
    color: rgb(237 118 53 / var(--tw-text-opacity)) !important;
}
.text-\[\#F1A240\] {
    --tw-text-opacity: 1 !important;
    color: rgb(241 162 64 / var(--tw-text-opacity)) !important;
}
.text-\[\#FFFFFF\] {
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
.text-\[\#ed7635\] {
    --tw-text-opacity: 1 !important;
    color: rgb(237 118 53 / var(--tw-text-opacity)) !important;
}
.text-\[\#ff4d4f\] {
    --tw-text-opacity: 1 !important;
    color: rgb(255 77 79 / var(--tw-text-opacity)) !important;
}
.text-black {
    --tw-text-opacity: 1 !important;
    color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}
.text-main-button-color {
    color: var(--main-button-color) !important;
}
.text-main-font-color {
    color: var(--main-font-color) !important;
}
.text-main-left-menu-color {
    color: var(--main-left-menu-font-icon) !important;
}
.text-preview-theme-font-color {
    color: var(--preview-theme-font-color) !important;
}
.text-red-500 {
    --tw-text-opacity: 1 !important;
    color: rgb(239 68 68 / var(--tw-text-opacity)) !important;
}
.text-red-600 {
    --tw-text-opacity: 1 !important;
    color: rgb(220 38 38 / var(--tw-text-opacity)) !important;
}
.text-white {
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
.underline {
    text-decoration-line: underline !important;
}
.underline-offset-2 {
    text-underline-offset: 2px !important;
}
.antialiased {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
}
.\!opacity-50 {
    opacity: 0.5 !important;
}
.opacity-50 {
    opacity: 0.5 !important;
}
.shadow {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-\[0_4px_16px_rgba\(0\2c 0\2c 0\2c 0\.08\)\] {
    --tw-shadow: 0 4px 16px rgba(0,0,0,0.08) !important;
    --tw-shadow-colored: 0 4px 16px var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-\[0_8_32_rgba\(0_0_0_0\.04\)\] {
    --tw-shadow: 0 8 32 rgba(0 0 0 0.04) !important;
    --tw-shadow-colored: 0 8 32 var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-\[0px_0px_8px_rgba\(0\2c 0\2c 0\2c 0\.04\)\] {
    --tw-shadow: 0px 0px 8px rgba(0,0,0,0.04) !important;
    --tw-shadow-colored: 0px 0px 8px var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-\[0px_8px_32px_rgba\(0\2c 0\2c 0\2c 0\.04\)\] {
    --tw-shadow: 0px 8px 32px rgba(0,0,0,0.04) !important;
    --tw-shadow-colored: 0px 8px 32px var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.outline-none {
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
}
.outline {
    outline-style: solid !important;
}
.blur {
    --tw-blur: blur(8px) !important;
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.\!filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.delay-150 {
    transition-delay: 150ms !important;
}
.\[r\:N\4 D\@\4 D\@\4 D\@\4 D\@\4\FFFD\FFFD\FFFD\FFFD\FFFD\10O\FFFDn\] {
    r: ND@D@D@D@�����O�n;
}

.or-by-login button path {
  fill: var(--main-button-color) !important;
}

.ant-modal-footer .ant-btn-default {
    color: var(--main-button-color) !important;
}

.ant-col.ant-form-item-label {
    font-family: var(--main-font-family);
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: var(--main-font-color);
}

.ant-menu-title-content > a {
    font-family: var(--main-font-family);
    color: var(--main-left-menu-font-icon);
}

.ant-col.ant-form-item-label > label {
    font-family: var(--main-font-family);
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: var(--main-font-color);
}

.ant-checkbox-checked .ant-checkbox-inner {
    border-color: var(--main-button-color) !important;
    background-color: var(--main-button-color) !important;
}

.ant-checkbox-checked::after {
    border-color: var(--main-button-color) !important;
}

.narrow-collapse-menu > svg > rect:first-child {
    fill: var(--main-extend-narrow-bg) !important;
}

.dash-theme:hover path {
  fill: white !important;
}

.ant-btn[disabled] {
  background-color: #aea8a5 !important;
  border-color: #aea8a5 !important;
}

.ant-input-affix-wrapper-disabled {
  background-color: transparent;
}

.ant-input-group.ant-input-group-compact .ant-select-selector {
  border-radius: 16px 0 0 16px !important;
}

.container {
  width: 100%;
  height: 56px;
  padding: 0;
}

.character {
  font-size: 18px;
  color: #32302d;
  background-color: #ffffff;
  border: 1px solid #e9e6e5;
  border-radius: 12px;
  margin-left: 8px;
  font-family: Montserrat, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  padding: 14px 0;
}

.character:first-child {
  margin-left: 0;
}

.character--inactive {
  background-color: rgba(255, 255, 255, 0.1);
}

.character--selected {
  border: 2px solid #ed7635;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 16px;
  height: 48px;
  width: 100%;
  padding: 8px 16px;
}

.ant-select-dropdown {
  border-radius: 12px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.ant-modal-content .ant-modal-header,
.ant-modal-content .ant-modal-footer {
  border-radius: inherit;
  border-bottom: none;
}

.ant-modal-content .ant-modal-footer {
  border-top: none;
  text-align: center;
  display: flex;
  justify-content: center;
}

.ant-modal-content {
  border-radius: 32px;
  padding: 14px 20px;
}

.btn-disable-background:disabled {
  background-color: transparent !important;
  border-color: transparent !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-disabled {
  background-color: transparent !important;
}

/* sidebar */

.ant-menu-submenu-selected,
.ant-menu-submenu-active,
.ant-menu-item-active,
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-selected,
.ant-menu-light .ant-menu-submenu-title:hover,
.ant-menu-item a:hover {
  color: var(--main-left-menu-highlight-color) !important;
  background: none !important;
}

.ant-menu-inline.ant-menu-root > .ant-menu-item {
  margin: 13px 0 !important;
}

.ant-menu-light .ant-menu-item:hover path,
.ant-menu-light .ant-menu-submenu-title:hover path {
  fill: var(--main-left-menu-font-icon);
}

.ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-left: 4px solid var(--main-left-menu-highlight-stripe-color) !important;
  border-radius: 2px;
  content: '';
  right: unset;
  border-right: unset;
}

/* end side bar */

/* end preview theme side bar */

.ant-dropdown-menu {
  border-radius: 12px !important;
  padding: 4px;
  background-color: #faf9f9 !important;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding-right: 35px;
  color: var(--main-font-color) !important;
  font-family: var(--main-font-family) !important;
}
.ant-form-item-required.ant-form-item-no-colon {
  color: var(--main-font-color);
  font-family: var(--main-font-family);
}

.layout-content:hover,
.layout-sidebar:hover {
  overflow-y: overlay !important;
}

.box-upload > span {
    width: 100%;
    background-color: transparent;
}

.box-upload .ant-upload {
    border-width: 0px;
}

.custom-tab .ant-tabs-tab-active .ant-tabs-tab-btn {
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.style_input_custom_login_page .ant-input-number-handler-wrap {
  border-radius: 0 10px 10px 0;
  background-color: transparent;
}

.ant-input:hover,
.ant-select .ant-select-selector:hover,
.ant-picker:hover,
.ant-input-affix-wrapper:hover,
.ant-input-number:hover {
    border-color: var(--main-button-color) !important;
}

.ant-input-number {
    display: flex;
    align-items: center;
    padding-left: 5px;
}

.ant-input-number-disabled {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.custom-text-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 150px;
  overflow: hidden;
}

.icon-hover:hover path {
    fill: var(--main-button-color);
}

/* custom-tab */

.custom-tab .ant-tabs-nav {
    margin-bottom: 1.5rem;
}

.custom-tab .ant-tabs-nav::before {
    border-bottom-width: 0px !important;
}

.custom-tab .ant-tabs-tab {
    margin-left: 0.75rem;
    border-radius: 3rem;
    border-width: 1px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity));
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.custom-tab .ant-tabs-tab:first-child {
    margin-left: 0px !important;
}

.custom-tab .ant-tabs-tab-active {
    border-color: var(--main-button-color);
    background-color: var(--main-button-color);
}

.custom-tab .ant-tabs-tab:not(.ant-tabs-tab-active):hover .ant-tabs-tab-btn,
.custom-tab .ant-tabs-tab-btn:focus {
    color: var(--main-button-color);
}

.custom-tab .ant-tabs-ink-bar {
    background-color: transparent;
}

.ant-picker {
    height: 3rem;
    width: 100%;
    border-radius: 0.75rem;
}

.ant-picker.ant-picker-disabled {
    background-color: transparent;
}

.ant-picker-focused {
    border-color: var(--main-button-color) !important;
}

.ant-picker-range .ant-picker-active-bar {
    background-color: var(--main-button-color) !important;
}

/* ckEditor */

.ck.ck-editor__main > .ck-editor__editable {
    min-height: 90px !important;
    border-bottom-left-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
    --tw-border-opacity: 1 !important;
    border-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
    --tw-shadow: 0 0 #0000 !important;
    --tw-shadow-colored: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
    --tw-border-opacity: 1 !important;
    border-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
}

.ant-table-cell {
    font-weight: 700;
}

.table-hover-component .ant-table-tbody > tr.ant-table-row > td:first-child {
    border-left-width: 1px !important;
    border-style: solid !important;
    border-left-color: transparent;
}

.table-hover-component .ant-table-tbody > tr.ant-table-row > td:last-child {
    border-right-width: 1px !important;
    border-style: solid !important;
    border-right-color: transparent;
}

.table-hover-component .ant-table-tbody > tr.ant-table-row > td,
.table-hover-component .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 0px !important;
    border-right-width: 0px !important;
    border-style: solid !important;
    border-top-color: transparent;
    border-bottom-color: transparent;
}

.table-hover-component .ant-table-tbody > tr.ant-table-row:hover > td:first-child,
.table-hover-component .ant-table-tbody > tr > td.ant-table-cell-row-hover:first-child {
    border-left-width: 1px !important;
    border-style: solid !important;
    border-left-color: var(--main-button-color);
}

.table-hover-component .ant-table-tbody > tr.ant-table-row:hover > td:last-child,
.table-hover-component .ant-table-tbody > tr > td.ant-table-cell-row-hover:last-child {
    border-right-width: 1px !important;
    border-style: solid !important;
    border-right-color: var(--main-button-color);
}

.table-hover-component .ant-table-tbody > tr.ant-table-row:hover > td,
.table-hover-component .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 0px !important;
    border-right-width: 0px !important;
    border-style: solid !important;
    border-top-color: var(--main-button-color);
    border-bottom-color: var(--main-button-color);
}

.ant-switch-checked {
    background-color: var(--main-button-color) !important;
}

.session-detail-preview > * {
    pointer-events: none !important;
    margin: 0px !important;
    width: 100% !important;
    cursor: not-allowed;
    overflow: hidden !important;
    overflow-wrap: break-word !important;
}

/*button-more-action*/

.btn-action:hover path {
    fill: var(--main-button-color);
}

.hover\:\!border-\[\#E9E6E5\]:hover {
    --tw-border-opacity: 1 !important;
    border-color: rgb(233 230 229 / var(--tw-border-opacity)) !important;
}

.hover\:\!border-main-button-color:hover {
    border-color: var(--main-button-color) !important;
}

.hover\:border-\[\#ED7635\]:hover {
    --tw-border-opacity: 1 !important;
    border-color: rgb(237 118 53 / var(--tw-border-opacity)) !important;
}

.hover\:bg-main-button-color:hover {
    background-color: var(--main-button-color) !important;
}

.hover\:\!text-\[\#E9E6E5\]:hover {
    --tw-text-opacity: 1 !important;
    color: rgb(233 230 229 / var(--tw-text-opacity)) !important;
}

.hover\:\!text-main-button-color:hover {
    color: var(--main-button-color) !important;
}

.hover\:text-\[\#BE5E2A\]:hover {
    --tw-text-opacity: 1 !important;
    color: rgb(190 94 42 / var(--tw-text-opacity)) !important;
}

.hover\:text-\[\#ED7635\]:hover {
    --tw-text-opacity: 1 !important;
    color: rgb(237 118 53 / var(--tw-text-opacity)) !important;
}

.focus\:border-\[\#E9E6E5\]:focus {
    --tw-border-opacity: 1 !important;
    border-color: rgb(233 230 229 / var(--tw-border-opacity)) !important;
}

@media (max-width: 1535px) {
    .\32xl\:flex {
        display: flex !important;
    }
    .\32xl\:w-\[32\.2\%\] {
        width: 32.2% !important;
    }
    .\32xl\:w-\[49\%\] {
        width: 49% !important;
    }
    .\32xl\:p-16 {
        padding: 4rem !important;
    }
    .\32xl\:pr-0 {
        padding-right: 0px !important;
    }
}

@media (min-width: 1535px) {
    .\32xl-min\:w-\[49\%\] {
        width: 49% !important;
    }
}

@media (max-width: 1279px) {
    .xl\:top-\[0\] {
        top: 0 !important;
    }
    .xl\:my-5 {
        margin-top: 1.25rem !important;
        margin-bottom: 1.25rem !important;
    }
    .xl\:my-6 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }
    .xl\:block {
        display: block !important;
    }
    .xl\:flex {
        display: flex !important;
    }
    .xl\:hidden {
        display: none !important;
    }
    .xl\:w-1\/2 {
        width: 50% !important;
    }
    .xl\:w-2\/3 {
        width: 66.666667% !important;
    }
    .xl\:w-\[100\%\] {
        width: 100% !important;
    }
    .xl\:w-\[32\%\] {
        width: 32% !important;
    }
    .xl\:w-\[49\%\] {
        width: 49% !important;
    }
    .xl\:w-\[calc\(33\.33\%-0\.5rem\)\] {
        width: calc(33.33% - 0.5rem) !important;
    }
    .xl\:w-\[calc\(33\.33\%_-_1rem\)\] {
        width: calc(33.33% - 1rem) !important;
    }
    .xl\:w-\[calc\(50\%_-_0\.375rem\)\] {
        width: calc(50% - 0.375rem) !important;
    }
    .xl\:w-\[calc\(50\%_-_0\.5rem\)\] {
        width: calc(50% - 0.5rem) !important;
    }
    .xl\:w-\[calc\(50\%_-_0\.75rem\)\] {
        width: calc(50% - 0.75rem) !important;
    }
    .xl\:w-full {
        width: 100% !important;
    }
    .xl\:min-w-\[calc\(33\.33\%_-_0\.75rem\)\] {
        min-width: calc(33.33% - 0.75rem) !important;
    }
    .xl\:min-w-fit {
        min-width: -moz-fit-content !important;
        min-width: fit-content !important;
    }
    .xl\:basis-\[calc\(50\%_-_0\.5rem\)\] {
        flex-basis: calc(50% - 0.5rem) !important;
    }
    .xl\:flex-col {
        flex-direction: column !important;
    }
    .xl\:items-start {
        align-items: flex-start !important;
    }
    .xl\:justify-start {
        justify-content: flex-start !important;
    }
    .xl\:overflow-x-auto {
        overflow-x: auto !important;
    }
    .xl\:p-16 {
        padding: 4rem !important;
    }
    .xl\:px-16 {
        padding-left: 4rem !important;
        padding-right: 4rem !important;
    }
    .xl\:py-10 {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;
    }
    .xl\:py-7 {
        padding-top: 1.75rem !important;
        padding-bottom: 1.75rem !important;
    }
    .xl\:text-2xl {
        font-size: 1.5rem !important;
        line-height: 2rem !important;
    }
    .xl\:text-base {
        font-size: 1rem !important;
        line-height: 1.5rem !important;
    }
    .xl\:text-sm {
        font-size: 0.875rem !important;
        line-height: 1.25rem !important;
    }
    .xl\:text-xs {
        font-size: 0.75rem !important;
        line-height: 1rem !important;
    }
}

@media (min-width: 1279px) {
    .xl-min\:mb-0 {
        margin-bottom: 0px !important;
    }
    .xl-min\:w-\[710px\] {
        width: 710px !important;
    }
    .xl-min\:w-\[calc\(33\.33\%_-_0\.5rem\)\] {
        width: calc(33.33% - 0.5rem) !important;
    }
    .xl-min\:w-\[calc\(33\.33\%_-_1rem\)\] {
        width: calc(33.33% - 1rem) !important;
    }
}

@media (max-width: 1023px) {
    .lg\:block {
        display: block !important;
    }
    .lg\:flex {
        display: flex !important;
    }
    .lg\:h-\[3rem\] {
        height: 3rem !important;
    }
    .lg\:h-auto {
        height: auto !important;
    }
    .lg\:min-h-\[calc\(100vh-65px\)\] {
        min-height: calc(100vh - 65px) !important;
    }
    .lg\:w-\[20\%\] {
        width: 20% !important;
    }
    .lg\:w-\[31\.2\%\] {
        width: 31.2% !important;
    }
    .lg\:w-\[32\.2\%\] {
        width: 32.2% !important;
    }
    .lg\:w-\[3rem\] {
        width: 3rem !important;
    }
    .lg\:w-\[47\%\] {
        width: 47% !important;
    }
    .lg\:w-\[48\%\] {
        width: 48% !important;
    }
    .lg\:w-\[49\%\] {
        width: 49% !important;
    }
    .lg\:w-\[50\%\] {
        width: 50% !important;
    }
    .lg\:w-\[calc\(33\.33\%_-_0\.5rem\)\] {
        width: calc(33.33% - 0.5rem) !important;
    }
    .lg\:w-\[calc\(33\.33\%_-_0\.67rem\)\] {
        width: calc(33.33% - 0.67rem) !important;
    }
    .lg\:w-\[calc\(40\%-0\.5rem\)\] {
        width: calc(40% - 0.5rem) !important;
    }
    .lg\:w-\[calc\(50\%_-_0\.25rem\)\] {
        width: calc(50% - 0.25rem) !important;
    }
    .lg\:w-\[calc\(50\%_-_0\.375rem\)\] {
        width: calc(50% - 0.375rem) !important;
    }
    .lg\:w-\[calc\(50\%_-_0\.5rem\)\] {
        width: calc(50% - 0.5rem) !important;
    }
    .lg\:w-\[calc\(50\%_-_0\.75rem\)\] {
        width: calc(50% - 0.75rem) !important;
    }
    .lg\:w-\[calc\(60\%-0\.5rem\)\] {
        width: calc(60% - 0.5rem) !important;
    }
    .lg\:w-full {
        width: 100% !important;
    }
    .lg\:min-w-\[calc\(50\%_-_0\.5rem\)\] {
        min-width: calc(50% - 0.5rem) !important;
    }
    .lg\:max-w-fit {
        max-width: -moz-fit-content !important;
        max-width: fit-content !important;
    }
    .lg\:basis-\[20\%\] {
        flex-basis: 20% !important;
    }
    .lg\:basis-auto {
        flex-basis: auto !important;
    }
    .lg\:flex-wrap {
        flex-wrap: wrap !important;
    }
    .lg\:p-0 {
        padding: 0px !important;
    }
    .lg\:px-2 {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }
    .lg\:pb-4 {
        padding-bottom: 1rem !important;
    }
}

@media (max-width: 767px) {
    .md\:block {
        display: block !important;
    }
    .md\:flex {
        display: flex !important;
    }
    .md\:w-\[32\%\] {
        width: 32% !important;
    }
    .md\:w-\[48\%\] {
        width: 48% !important;
    }
    .md\:w-\[50\%\] {
        width: 50% !important;
    }
    .md\:w-\[calc\(50\%_-_0\.75rem\)\] {
        width: calc(50% - 0.75rem) !important;
    }
    .md\:w-full {
        width: 100% !important;
    }
    .md\:min-w-\[calc\(50\%_-_0\.5rem\)\] {
        min-width: calc(50% - 0.5rem) !important;
    }
    .md\:flex-wrap {
        flex-wrap: wrap !important;
    }
    .md\:p-0 {
        padding: 0px !important;
    }
    .md\:pb-4 {
        padding-bottom: 1rem !important;
    }
}

@media (max-width: 639px) {
    .sm\:fixed {
        position: fixed !important;
    }
    .sm\:left-\[0\%\] {
        left: 0% !important;
    }
    .sm\:top-\[10\%\] {
        top: 10% !important;
    }
    .sm\:ml-0 {
        margin-left: 0px !important;
    }
    .sm\:block {
        display: block !important;
    }
    .sm\:hidden {
        display: none !important;
    }
    .sm\:w-\[100\%\] {
        width: 100% !important;
    }
    .sm\:w-\[calc\(50\%_-_0\.375rem\)\] {
        width: calc(50% - 0.375rem) !important;
    }
    .sm\:w-\[calc\(50\%_-_0\.5rem\)\] {
        width: calc(50% - 0.5rem) !important;
    }
    .sm\:w-full {
        width: 100% !important;
    }
    .sm\:min-w-\[calc\(50\%_-_0\.5rem\)\] {
        min-width: calc(50% - 0.5rem) !important;
    }
    .sm\:basis-\[20\%\] {
        flex-basis: 20% !important;
    }
    .sm\:basis-\[30\%\] {
        flex-basis: 30% !important;
    }
    .sm\:flex-col {
        flex-direction: column !important;
    }
    .sm\:flex-wrap {
        flex-wrap: wrap !important;
    }
    .sm\:items-start {
        align-items: flex-start !important;
    }
    .sm\:justify-start {
        justify-content: flex-start !important;
    }
    .sm\:gap-2 {
        gap: 0.5rem !important;
    }
    .sm\:gap-3 {
        gap: 0.75rem !important;
    }
    .sm\:gap-4 {
        gap: 1rem !important;
    }
    .sm\:p-0 {
        padding: 0px !important;
    }
    .sm\:pb-4 {
        padding-bottom: 1rem !important;
    }
    .sm\:text-center {
        text-align: center !important;
    }
    .sm\:text-sm {
        font-size: 0.875rem !important;
        line-height: 1.25rem !important;
    }
    .sm\:text-xl {
        font-size: 1.25rem !important;
        line-height: 1.75rem !important;
    }
}
